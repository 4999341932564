:root {
  --success-100: #CDFEE1;
  --success-300: #29845A;
  --success-500: #126B43;

  --danger-100: #FEE9E8;
  --danger-300: #E51C00;
  --danger-500: #8E1F0B;

  --warning-100: #FFF1E3;
  --warning-300: #FFB800;
  --warning-500: #5E4200;
}

body{
  background-color: var(--surface);
  margin: 0px auto;
  padding-bottom: 124px;
}
ul{
  list-style-type: none;
}

.logo{
  height: 48px;
  width: 48px;
}
.logo:hover{
  cursor: pointer;
}
.hero{
  background-color: var(--surface-container);
  padding: 80px 0px 40px 0px;
}
.hero-inner{
  padding: 40px;  
}
.sub-title{
  font-size: 22px;
}
.hero-inner-text{
  padding: 40px 0px;  
}
.hero-lesson-thumbnail-cover{
  height: 48px;
  width: 48px;
  background-color: var(--surface);
  border: 1px solid var(--outline-variant);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.hero-lesson-thumbnails-1,
.hero-lesson-thumbnails-2{
  width: 124px;
  position: relative;
  display: flex;
  height: 100%;
}
.hero-lesson-thumbnails-1 #hero-thumbnail-1{
  right: 16px;
  top: 0;
  transform: rotate(13deg);

}
.hero-lesson-thumbnails-1 #hero-thumbnail-2{
  left: 0;
  top: 64px;
  transform: rotate(-21deg);

}
.hero-lesson-thumbnails-1 #hero-thumbnail-3{
  right: 0;
  top: 124px;
  transform: rotate(8deg);

}
.hero-lesson-thumbnails-2 #hero-thumbnail-1{
  left: 16px;
  top: 0;
  transform: rotate(-15deg);
}
.hero-lesson-thumbnails-2 #hero-thumbnail-2{
  right: 16px;
  top: 64px;
  transform: rotate(16deg);

}
.hero-lesson-thumbnails-2 #hero-thumbnail-3{
  left: 0;
  top: 124px;
  transform: rotate(5deg);

}

.link{
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.grey-surface-100{
  background-color: var(--grey-100);
}
.grey-surface-200{
  background-color: var(--grey-200);
}
.success-text{
  color: var(--success-500);
}
.source-link{
  color: var(--grey-300);
}
textarea{
  font-size: 18px;
  line-height: 30px;
  padding: 8px;
}



header{
  position: fixed;
  height: 64px;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  align-items: center;
  border-bottom: 1px solid #F2F0E9;
  z-index: 999;
}
.quiz-header{
  background-color: #ffffff;
}
header.navbar-top{
  background-color: var(--surface-container);
}
header.navbar-scrolled{
  background-color: #ffffff;
}
header .logo{
  width: 56px;
  height: 56px;
}
.constrained-width{
  width: 600px;
  margin: 0 auto;
}
.less-constrained-width{
  width: 720px;
  margin: 0 auto;
}
.container-padding{
  padding: 124;
}
/* PROGRESS BAR */

.full-bar{
  width: 600px;
  height: 8px;
  border-radius: var(--border-radius);
  background-color: #EEEEEE;
  position: relative;
}
.progress-bar{
  height: 8px;
  border-radius: var(--border-radius);
  background-color: var(--on-surface-variant);
  position: absolute;
  left: 0px;
  top: 0px;
}

/* PROGRESS BAR */



/* QUESTION */



.no-feedback, .correct, .incorrect, .feedback{
  padding: 16px;
  border-radius: 8px;
}
.no-feedback:hover{
  cursor: pointer;
}

.no-feedback, .feedback{
  border: 2px solid var(--grey-200);
}
.questionComponent{
  border-radius: var(--border-radius);
  border: 1px solid var(--outline-variant);
  background-color: var(--surface-container);
  padding: 24px;
}
.option-img li{
  display: flex;
  align-items: center;
  justify-content: center;
}
.option-img .col-2 li{
  padding: 0px 40px;
}
.option-img .col-2 li img{
  height: 64px
}
.image-question-options{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  row-gap: 8px;
  column-gap: 8px;
}
.image-question-options li{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex: 1 1 49%;  
}

.image-question-options.two-options li {
  box-sizing: border-box;
}

@keyframes correctAnimation {
  0% {background-color: white; border: 2px solid var(--grey-200);}
  100% {background-color: var(--success-100);  border: 2px solid var(--success-300) ;}
}
.incorrect{
  animation: inCorrectAnimation 0.5s ease forwards;
}
@keyframes inCorrectAnimation {
  0% {background-color: white; border: 2px solid var(--grey-200);}
  100% {background-color: var(--danger-100);  border: 2px solid var(--danger-300) ;}
}
/* FEEDBACK */



.option.correct{
  background-color: var(--success-container);
  border: 1px solid var(--success);
  border-bottom: 4px solid var(--success);
}

.option.incorrect{
  background-color: var(--error-container);
  border: 1px solid var(--error);
  border-bottom: 4px solid var(--error);
}



/* FEEDBACK */
.feedback-container{
  margin-top: 24px;
}
.correct-feedback h6{
  color: var(--success-500);
}
.incorrect-feedback h6{
  color: var(--danger-500);
}

.App{
  margin-bottom: 280px;
}

/* TABLE */

.table-container {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--outline-variant);
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--surface-container-low);
}

th, td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid var(--outline-variant);
  border-right: 1px solid var(--outline-variant);
}
td:first-child,
th:first-child {
  background-color: var(--grey-100);
}
td:last-child,
th:last-child {
  border-right: none;
}

th {
  background-color: var(--grey-100);
}

/* ACCENT INFO */
.accent-info{
  background-color: var(--grey-100);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.cross-icon-container{
  border: 1px solid var(--grey-200);
  background-color: var(--grey-100);
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.success-container{
  background-color: var(--success-100);
  color: var(--success-500);
  padding: 8px 16px;
  width: fit-content;
  border-radius: 8px;
}

.product-img{
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

/* HOME PAGE */
.lesson-thumbnail-cover-s{
  width: 98px;
  height: fit-content;
  display: flex;
  padding: 8px;
  
  border-radius: 8px;
}
.lesson-thumbnail-cover-s img{
  width: 100%;
  height: auto;
}




.secondary-text{
  color: var(--grey-300);
}

.label{
  padding: 4px 16px;
  background-color: var(--grey-200);
  font-weight: 500;
  display: flex;
  width: fit-content;
  flex-direction: row;
  column-gap: 12px;
  border-radius: 8px;
  font-family: "Outfit", sans-serif;
  align-items: center;
}
.label.label-dark{
  background-color: var(--grey-150);
  color: var(--grey-400);
}
.label.label-dark img{
  color: var(--grey-100);
}

#crossed-out-text{
  position: relative;
}
#line{
  position: absolute;
  top: -7%;
  width: 100%;
}
#crossed-out-text{
  font-weight: 400;
}



#status-message{
  width: 100%;
  margin-top: 8px;
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  border-radius: 8px;
}
#status-message.success-status{
  background-color: var(--success-100);
  color: var(--success-500);
  padding: 16px;
}

#status-message.error-status{
  background-color: var(--danger-100);
  color: var(--danger-500);
  padding: 16px;
}
.background-accent{
  background-color: var(--secondary-color-light);
}
.opacity-50{
  opacity: 50%;
}


/* ---------- OPTIONS -------- */

.imageWrapper{
  background-color: var(--surface);
  overflow: hidden;
  border: 1px solid var(--outline-variant);
}
.option .imageWrapper{
  border: none;
  background-color: var(--surface-container-low);
}
.imageDesktopLarge .imageWrapper{
  height: 348px;
}
.imageWrapper .questionImage{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.option{
  border-radius: var(--border-radius);
  border: 1px solid var(--outline-variant);
  border-bottom:4px solid var(--outline-variant);
  cursor: pointer;
  border-bottom: 4px solid var(--outline-variant);
  background-color: var(--surface-container-low);
  padding: 16px;
}
.option.selected{
  border: 2px solid var(--outline);
}

.option:hover{
  background-color: var(--surface-container);
  color: var(--color-white);
}
.image-question-options .option:hover{
  background-color: var(--surface);
}



/* ---------- USER FEEDBACK -------- */
.user-feedback label{
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  min-width: 96px;
  flex: 1 1 19%;
  text-align: center;
  row-gap: 8px;
  border-radius: var(--border-radius);
  border: 1px solid var(--outline-variant);
  border-bottom:4px solid var(--outline-variant) ;
  cursor: pointer;
  border-bottom: 4px solid var(--outline-variant);
  background-color: var(--surface-container-low);
}
.user-feedback label:hover{
  cursor: pointer;
  background-color: var(--grey-100);
}
.user-feedback label p{
  line-height: 24px;
}

.user-feedback input{
  display: none;
}
.user-feedback-form{
  border: none;
  width: 100%;
  padding: 0;
}
.user-feedback-form textarea{
  height: 96px;
  border: 1px solid var(--outline-variant);
  border-radius: 8px;
}
.user-feedback-form button{
  width: fit-content;
}


/* Loader animation */
.loader {
  border: 4px solid var(--outline);
  border-radius: 50%;
  border-top: 4px solid var(--outline-variant);
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle;
}


.item {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* ---- GENERAL ----  */
.button{
  padding: 12px 24px;
  border-radius: var(--border-radius);
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  font-weight: 500;
}

/* ---- TYPES ----  */
.primary{
  background-color: var(--primary-color);
  color: white;
}

/* ---- LESSON CARD----  */
.lesson-card-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px; /* Adjust the gap between the cards as needed */;
}

.lesson-card{
  width: 100%;
  min-width: 240px;
  justify-content: space-between;

  border: 1px solid var(--outline-variant);
  transition: border 0.3s ease, margin 0.3s ease; /* Smooth transition */
}
.lesson-card-container{
  padding: 0px 80px;
}
.lesson-card-icon-container-sm{
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius);
}
.lesson-card-icon-container-lg{
  width: 80px;
  height: 80px;
  border-radius: var(--border-radius);
}
.lesson-card-icon-container-lg img{
  width: 40px;
  height: 40px;
}
.dashed-top-border{
  border-top: 1px dashed var(--outline-variant);
  padding-top: 8px;
}
.lesson-card:hover{
  cursor: pointer;
  border-bottom: 4px solid var(--outline-variant);
  border-right: 4px solid var(--outline-variant);
  margin-top: -4px;
  margin-left: -4px;
}    
.intro-container{
  padding-top: 124px;
}
.top-section-padding{
  padding-top: 124px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.border-color{
  background-color: var(--outline-variant);
}
#matching-option:hover{
  background-color: var(--surface);
  color: var(--color-white);
}
.no-underline{
  text-decoration: none;
}
.button.secondary{
  border: 1px solid var(--outline);
  background-color: var(--surface-container);
  color: var(--on-surface);
}

@media (max-width: 500px) {
  h1{
    font-size: 40px;
  }
  h2{
    font-size: 30px;
    line-height: 36px;
  }
  h3{
    font-size: 28px;
  }
  h4{
    font-size: 24px;
    font-weight: 600;
  }
  h5{
    font-size: 20px;
    font-weight: 600;
  }
  h6{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  p, li, td, th{
    font-size: 18px;
    line-height: 24px;
  }
  

  .root{
    width: 100%;
  }
  body{
    margin:24px 0 ;
    width: 100%;
  }
  .quiz-content button{
    margin: 0;
    width: 100%;
  }
  .quiz-button-container{
    display: flex;
    align-items: center;
    background-color: var(--surface);
    padding: 16px 16px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;

  }
  .constrained-width{
    width: 100%;
    padding: 0 16px;
  }
  .less-constrained-width{
    width: 100%;
    padding: 16px 16px;
  }
  header{
    padding: 8px 16px;
    column-gap: 16px;
    height: 48px;
  }

  header .logo{
    height: 48px;
    width: 48px;
  }
  .hero-inner{
    padding: 24px 16px;
  }
  header .full-bar{
    width: 100%;
  }
  .cross-icon-container{
    padding: 8px;
  }
  .image-question-options li {
    flex: 1 1 45%;
    padding: 8px;
  }
  .image-question-options.two-options li {
    flex: 1 1 100%; /* Style when there are 2 options */
  }
  .image-question-options li img{
    width: 100%;
  }
  

  /* .question-component{
    padding-top: 80px;
  } */
  .full-bar,
  .progress-bar{
    height: 8px;
  }

 
  .cross-icon-container{
    width: 32px;
    height: 32px;
  }
  .cross-icon-container img{
    width: 16px;
  }

  form{
    width: 100%;
  }
  .quiz-element:last-child{
    min-height: 100vh;
    height: 100%;
    padding-bottom: 100px;
    width: 100%;
  }
  .quiz-element{
    width: 100%;
  }
  .quiz-element:first-child{
    padding: 64px 0px !important;
  }

  .question-image-wrapper,
  .imageDesktopLarge .question-image-wrapper {
    height: auto;
    aspect-ratio: 5 / 3;
  }
  .user-feedback label{
    min-width:auto ;
    flex: 1 1 19%;
    padding: 16px 4px;
  }
  .user-feedback label p{
    display: none;
  }
  .hero{
    padding: 0px;
  }
  .hero-lesson-thumbnails-mobile{
    position: relative;
    padding: 48px 40px 0 40px;
    overflow: hidden;
  }
  .hero-lesson-thumbnails-mobile #hero-thumbnail-1{
    position: absolute;
    left: 40px;
    top: 0;
    transform: rotate(13deg);

  }
  .hero-lesson-thumbnails-mobile #hero-thumbnail-2{
    position: absolute;
    left: -16px;
    top: 48px;
    transform: rotate(-22.978deg);

  }
  .hero-lesson-thumbnails-mobile #hero-thumbnail-3{
    position: absolute;
    right: 24px;
    top: 0px;
    transform: rotate(-21deg);

  }
  .hero-lesson-thumbnails-mobile #hero-thumbnail-4{
    position: absolute;
    right: -16px;
    top: 64px;
    transform: rotate(8deg);

  }
  .lesson-card-container{
    padding: 0px 16px;
  }
  .intro-container{
    padding-top: 0px;
  }
  .questionComponent{
    padding: 16px 12px;
  }
  .top-section-padding{
    padding-top: 48px;
  }
   
 
}