:root{
   /* Color variables */
   --primary-color: #252525;
   --secondary: #E9E6DD;
   --surface: #FAF9F6;
   --surface-container-low: #FFFFFF;
   --surface-container: #F2F0E9;
   --surface-container-high: #E9E6DD;
   --outline: #787777;
   --outline-variant: #E2E2E2;
   --on-surface: #252525;
   --on-surface-variant: #5E5E5E;
   --error-container: #FAEAEA;
   --on-error-container: #970C0C;
   --error: #D21C1C;
   --error-outline: #F4D2D2;
   --success: #28A138;
   --success-container: #EBF4EC;
   --on-success-container: #2D7738;
   --success-outline: #D7EAD9;
   --info: #6B33FF;
   --info-container: #EFE9FF;
   --on-info-container: #3E00E3;

   /* Border radius */
   --border-radius: 4px;


}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/** FONTS **/

h1, h2, h3, h4, h5, h6, p, button, a, li, td, th, textarea {
  font-family: "Outfit", sans-serif;
  color: var(--grey-600);
}
h1{
  font-size: 48px;
}
h2{
  font-size: 40px;
}
h3{
  font-size: 32px;
}
h4{
  font-size: 28px;
  font-weight: 600;
}
h5{
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}
h6{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
p, li, td, th{
  font-size: 18px;
  line-height: 26px;
}
a{
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  text-decoration: underline;
}
p.semibold{
  font-weight: 500;
}
p.small-body{
  font-size: 14px;
  line-height: 18px;
}


/** POSITIONING **/
.vertical{
  display: flex;
  flex-direction: column;
}
.horizontal{
  display: flex;
  flex-direction: row;
}
.center-main-axis{
  justify-content: center;
}
.center-cross-axis{
  align-items: center;
}
.text-center{
  text-align: center;
}
.start-cross-axis{
  align-items: flex-start;
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}

/** SPACING **/
.v-xs{
  row-gap: 4px;
}
.v-s{
  row-gap: 8px;
}
.v-m{
  row-gap: 16px;
}
.v-l{
  row-gap: 24px;
}
.v-xl{
  row-gap: 40px;
}
.v-xxl{
  row-gap: 80px;
}
.v-huge{
  row-gap: 124px;
}
.v-humongous{
  row-gap: 248px;
}

.h-xs{
  column-gap: 4px;
}
.h-s{
  column-gap: 8px;
}
.h-m{
  column-gap: 16px;
}
.h-l{
  column-gap: 24px;
}
.h-xl{
  column-gap: 40px;
}
.h-xxl{
  column-gap: 80px;
}
.space-between{
  justify-content: space-between;
}

.fill{
  width: 100%;
}
.fill-half{
  width: 50%;
}
.fill-75{
  width: 75%;
}
.hug{
  width: auto;
}

.h-padding-s{
  padding-left: 8px;
  padding-right: 8px;
}
.h-padding-m{
  padding-left: 16px;
  padding-right: 16px;
}
.h-padding-l{
  padding-left: 24px;
  padding-right: 24px;
}
.h-padding-xl{
  padding-left: 40px;
  padding-right: 40px;
}
.h-padding-80{
  padding-left: 80px;
  padding-right: 80px;
}

.v-padding-s{
  padding-top: 8px;
  padding-bottom: 8px;
}
.v-padding-m{
  padding-top: 16px;
  padding-bottom: 16px;
}
.v-padding-l{
  padding-top: 24px;
  padding-bottom: 24px;
}
.v-padding-xl{
  padding-top: 40px;
  padding-bottom: 40px;
}
/** SIZES **/
.size-s{
  width: 8px;
  height: fit-content;
}
.size-m{
  width: 16px;
  height: fit-content;
}
.size-l{
  width: 24px;
  height: fit-content;
}
.size-xl{
  width: 32px;
  height: fit-content;
}
.size-xxl{
  width: 40px;
  height: fit-content;
}
.size-48{
  width: 48px;
  height: fit-content;
}
.size-xxxl{
  width: 64px;
  height: fit-content;
}
.size-big{
  width: 80px;
  height: fit-content;
}
.size-huge{
  width: 124px;
  height: fit-content;
}
.fit-content{
  width: fit-content;
}
.flex-1{
  flex: 1;
}

/** OTHER **/
.relative{
  position: relative;
}
.border-radius{
  border-radius: var(--border-radius);
}
.border{
  border: 1px solid var(--outline);
}
.border-variant{
  border: 1px solid var(--outline-variant);
}

/** COLOR CLASSES **/
.on-surface{
  color: var(--on-surface);
}
.on-surface-variant{
  color: var(--on-surface-variant);
}
.background-surface-container{
  background-color: var(--surface);
}
.background-surface-container-low{
  background-color: var(--surface-container-low);
}
.desktop-hide{
  display: none;
}
/** Media Queries **/
@media (max-width: 500px) {
  /** SPACING **/
.m-v-s{
  row-gap: 8px;
}
.m-v-m{
  row-gap: 16px;
}
.m-v-l{
  row-gap: 24px;
}
.m-v-xl{
  row-gap: 40px;
}
.m-v-xxl{
  row-gap: 80px;
}
.m-v-huge{
  row-gap: 124px;
}
.m-v-humongous{
  row-gap: 248px;
}

.m-h-s{
  column-gap: 8px;
}
.m-h-m{
  column-gap: 16px;
}
.m-h-l{
  column-gap: 24px;
}
.m-h-xl{
  column-gap: 40px;
}
.m-h-xxl{
  column-gap: 80px;
}

  .mobile-vertical{
    display: flex;
    flex-direction: column;
  }
  .mobile-horizontal{
    display: flex;
    flex-direction: row;
  }
  .mobile-fill{
    width: 100%;
  }
  .mobile-fill-half{
    width: 50%;
  }
  .mobile-fill-75{
    width: 75%;
  }
  .mobile-center-main-axis{
    justify-content: center;
  }
  .mobile-center-cross-axis{
    align-items: center;
  }
  .mobile-start-cross-axis{
    align-items: flex-start;
  }


  .mobile-h-padding-s{
    padding-left: 8px;
    padding-right: 8px;
  }
  .mobile-h-padding-m{
    padding-left: 16px;
    padding-right: 16px;
  }
  .mobile-h-padding-l{
    padding-left: 24px;
    padding-right: 24px;
  }
  .mobile-h-padding-xl{
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .mobile-v-padding-s{
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .mobile-v-padding-m{
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .mobile-v-padding-l{
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .mobile-v-padding-xl{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .m-hide{
    display: none !important;
  }
  .desktop-hide{
    display:block;
  }
  /** FONTS **/

  h1{
    font-size: 32px;
    line-height: 40px;
  }
  h2{
    font-size: 28px;
  }
  h3{
    font-size: 24px;
  }
  h4{
    font-size: 20px;
    font-weight: 600;
  }
  h5{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
  h6{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  p, li, td, th{
    font-size: 16px;
    line-height: 20px;
  }
  a{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: underline;
  }
  p.semibold{
    font-weight: 500;
  }
}