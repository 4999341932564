.banner{
    padding: 16px;
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
}
.warning{
    fill: var(--on-error-container);
    stroke: var(--on-error-container);
    background-color: var(--error-container);
}

.success{
    background-color: var(--success-container);
}