/* Modal Container */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears above other content */
}

/* Modal Content */
.modalContent {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-out; /* Optional: fade-in animation */
}

/* Close Button */
.modalCloseIcon {
   background: none;
   border: none;
   display: flex;
   justify-content: flex-end;
}

/* Fade-in animation (optional) */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Modal content as a bottom sheet on mobile */
@media (max-width: 768px) {
    .modalContent {
        width: 100%;
        max-width: none;
        border-radius: 16px 16px 0 0; /* Rounded corners at the top */
        padding: 1rem;
        margin: 0;
        animation: slideUp 0.3s ease-out; /* Optional: slide-up animation */
    }
    .modalOverlay{
        align-items: flex-end;
    }
}
