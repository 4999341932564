.avatarIcon{
    height: 40px;
    width: 40px;
    background-color: var(--info-container);
    color: var(--on-info-container);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 2px solid var(--on-info-container);
}
.avatarContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;

}
.avatarContainer:hover{
    background: var(--surface-container);
    outline: 1px solid var(--outline-variant);
    cursor: pointer;
    border-radius: var(--border-radius);
}
.dropdown{
    position: absolute;
    top: 80px;
    right: 88px;
    background-color: #ffffff;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction:column;
    row-gap: 8px;
    padding: 16px 8px;
    box-shadow: 0px 0px 8px 8px rgba(197, 188, 188, 0.1);
}



@media (max-width: 500px){
    .dropdown{
        position: absolute;
        top: 64px;
        right: 16px;
    }
    .avatarIcon{
        height: 32px;
        width: 32px;
    }
}