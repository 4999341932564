.menuItem{
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    min-width: 148px;
    padding: 8px;
}
.menuItem:hover{
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
    cursor: pointer;
}