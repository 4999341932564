.tag{
    display: flex;
    column-gap: 4px;
    padding: 4px 8px;
    border-radius: var(--border-radius);
    align-items: center;
    width: fit-content;
    height: fit-content;
}
.tag p{
    font-size: 16px;
    font-weight: 500;
}
.icon{
    height: 16px;
    width: 16px;
}
.outline.info{
    background-color: var(--info-container);
    color: var(--on-info-container);
    border: 1px solid var(--on-info-container);
}
.outline.success{
    background-color: var(--success-container);
    color: var(--on-success-container);
    border: 1px solid var(--success-outline);
    color: var(--on-success-container);
}
.filled.success{
    background-color: var(--success);
    color: #ffffff;
}
.filled.success .icon{
    fill: #ffffff;
    stroke: #ffffff;
}
.filled.error{
    background-color: var(--error);
    color: #ffffff;
}