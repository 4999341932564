.reference{
    position: absolute;
    bottom: 8px;
    left: 8px;
    background-color: var(--surface-container);
    padding: 4px 8px 4px 8px;
    text-decoration: none;
}
.reference:hover{
    background-color: var(--surface-container-high);
}
.productImage{
    height: 20px;
    width: 20px;
}
.imageProductText{
    color: var(--outline);
    font-weight: 600;
}