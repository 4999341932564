.questionComponent{
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    padding: 24px;
}
.questionImageWrapper{
    border: 1px solid var(--outline-variant);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--surface-container-high);
    border-radius: var(--border-radius);
}
@media (max-width: 500px) {
    .questionComponent{
        padding: 16px 12px;
    }
}