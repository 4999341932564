.option{
    padding: 16px;
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    cursor: pointer;
    border-bottom: 4px solid var(--outline-variant);
    background-color: var(--surface-container-low);
}

.option:hover{
    background-color: var(--surface);
    color: var(--color-white);
}

.option.correct{
    background-color: var(--success-container);
    border: 1px solid var(--success);
    border-bottom: 4px solid var(--success);
}

.option.incorrect{
    background-color: var(--error-container);
    border: 1px solid var(--error);
    border-bottom: 4px solid var(--error);
}