.inputField{
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    height: 48px;
    font-size: 16px;
    font-family: "Outfit", sans-serif;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    padding: 16px;
    align-items: center;
    background-color: #ffffff;
}
.inputField input{
    border: none;
    width: 100%;
    line-height: 21px;
    font-family: "Outfit", sans-serif;
    font-size: 18px;
}

.inputField input:focus{
    outline: none;
}
.inputField.focused{
    border: 2px solid var(--on-surface);
}
.inputField input::placeholder{
    font-family: "Outfit", sans-serif;
    color: var(--on-surface-variant);
    font-size: 18px;
}
.inputField .toggleIcon{
    cursor: pointer;
}