
/* ---- GENERAL ----  */
.button{
    padding: 8px 16px;
    border-radius: var(--border-radius);
    font-size: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    font-weight: 500;
    width: fit-content;
}

/* ---- TYPES ----  */
.primary{
    background-color: var(--primary-color);
    color: white;
}
.secondary{
  border: 1px solid var(--outline);
  background-color: var(--surface-container);
  color: var(--on-surface);
}

/* ---- SIZE ----  */
.fullWidth{
  width: 100%;
}
.small{
  padding: 8px 16px;
  height: 48px;
}
.large{
  padding: 16px 40px ;
  font-size: 18px;
}
/* ---- STATES----  */

button:hover{
    cursor: pointer;
}
.disabled {
    /* Disabled state styles */
    opacity: 0.3;
    cursor: not-allowed;
  }


/* ---- ADDITIONAL PROPERTIES ----  */
.icon{
    width: 24px;
    height: 24px;
    border-radius: 24px;
}
.topMargin{
  margin-top: 40px;
}


/* ---- RESPONSIVENESS ----  */

@media (max-width: 500px) {


.button-container{
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 8px;
    width: 100%;
    background-color: white;
  }
  button{
    width: 100%;
    justify-content: center;
    margin-top: 0;
    
  }
  .m-small-button{
    width: fit-content;
    font-size: 16px;
  }
}